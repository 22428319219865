import React, { Suspense, lazy } from "react";
import { Routes, Route } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";
const CookiePolicy = lazy(() => import("../views/CookiePolicy"));
const Home = lazy(() => import("./Home"));
const ErrorPage = lazy(() => import("../views/ErrorPage"));
// const About = lazy(() => import("../views/About"));
// const AFCCalendars = lazy(() => import("../views/Calendars/AFCCalendars"));
// const AllInternationalCalendars = lazy(() =>
//   import("../views/Calendars/AllInternationalCalendars")
// );
// const CAFCalendars = lazy(() => import("../views/Calendars/CAFCalendars"));
// const CONCACAFCalendars = lazy(() =>
//   import("../views/Calendars/CONCACAFCalendars")
// );
// const CONMEBOLCalendars = lazy(() =>
//   import("../views/Calendars/CONMEBOLCalendars")
// );
// const OFCCalendars = lazy(() => import("../views/Calendars/OFCCalendars"));
// const UEFACalendars = lazy(() => import("../views/Calendars/UEFACalendars"));
// const Contact = lazy(() => import("../views/Contact/Contact"));
// const Tournaments = lazy(() =>
//   import("../views/Calendars/Tournaments/Tournaments")
// );
// const RecentlyUpdated = lazy(() =>
//   import("../views/Calendars/RecentlyUpdated/RecentlyUpdated")
// );

const routesData = [
  {
    path: "/",
    exact: true,
    component: Home,
  },
  // {
  //   path: "/recently-updated",
  //   exact: false,
  //   component: RecentlyUpdated,
  // },
  // {
  //   path: "/tournaments",
  //   exact: false,
  //   component: Tournaments,
  // },
  // {
  //   path: "/calendars",
  //   exact: false,
  //   component: AllInternationalCalendars,
  // },
  // {
  //   path: "/calendars-afc",
  //   exact: false,
  //   component: AFCCalendars,
  // },
  // {
  //   path: "/calendars-caf",
  //   exact: false,
  //   component: CAFCalendars,
  // },
  // {
  //   path: "/calendars-concacaf",
  //   exact: false,
  //   component: CONCACAFCalendars,
  // },
  // {
  //   path: "/calendars-conmebol",
  //   exact: false,
  //   component: CONMEBOLCalendars,
  // },
  // {
  //   path: "/calendars-ofc",
  //   exact: false,
  //   component: OFCCalendars,
  // },
  // {
  //   path: "/calendars-uefa",
  //   exact: false,
  //   component: UEFACalendars,
  // },
  {
    path: "/cookie-policy",
    exact: false,
    component: CookiePolicy,
  },
  // {
  //   path: "/about",
  //   exact: false,
  //   component: About,
  // },
  // {
  //   path: "/contact",
  //   exact: false,
  //   component: Contact,
  // },
  {
    path: "*",
    exact: false,
    component: ErrorPage,
  },
];

export default function AllRoutes() {
  return (
    <Suspense
      fallback={
        <div className="spinner">
          <Spinner animation="border" role="status">
            <span className="sr-only"></span>
          </Spinner>
        </div>
      }
    >
      <Routes>
        {routesData.map((routeData) => (
          <Route
            key={routeData.path}
            exact={routeData.exact}
            path={routeData.path}
            element={<routeData.component />}
          />
        ))}
      </Routes>
    </Suspense>
  );
}
