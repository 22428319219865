import React from "react";
import Nav from "react-bootstrap/Nav";
import { Link } from "react-router-dom";
import "./footernav.css";

export default function FooterNav() {
  return (
    <div className="container">
      <Nav className="justify-content-end" id="footerNav">
        <Link to="/cookie-policy" id="cookiePolicyFooterNav">
          Cookie Policy
        </Link>
      </Nav>
    </div>
  );
}
