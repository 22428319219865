import { React } from "react";
import { HelmetProvider } from "react-helmet-async";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import FooterNav from "./Components/FooterNav";
import { BrowserRouter as Router, Link } from "react-router-dom";
import NavigationBar from "./Components/NavigationBar";
import Footer from "./Components/Footer";
import Banner from "./Components/Banner";
import AllRoutes from "./Components/AllRoutes";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import CookieConsent from "react-cookie-consent";
import TagManager from "react-gtm-module";
import Jumbotron from "react-bootstrap/Jumbotron";

/*eslint-env node*/

library.add(fab, faCalendarAlt);

const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GTM_ID,
};

TagManager.initialize(tagManagerArgs);

export default function App() {
  return (
    <Router>
      <HelmetProvider>
        <Container fluid className="wrapper">
          <CookieConsent
            rel="preload"
            location="bottom"
            cookieName="wsf_cookiesConsent"
            expires={365}
            style={{ background: "#E5E5E5", color: "#000000" }}
            hideOnAccept={true}
            flipButtons
            buttonText="Accept &amp; close"
            buttonStyle={{ background: "#3c454e", color: "#FFFFFF" }}
            buttonId="cookiesAcceptButton"
          >
            This website uses &apos;cookies&apos; to analyse our traffic. By
            continuing on this website or clicking &apos;Accept&apos; you agree
            to the storing of cookies on your device. Please read our{" "}
            <Link to="/cookie-policy" id="cookie-policy">
              Cookie Policy
            </Link>{" "}
            for more information.
          </CookieConsent>
          <Row className="navRow">
            <NavigationBar />
          </Row>
          <Row>
            <div className="Jumbo">
              <Jumbotron />
            </div>
          </Row>
          <Row>
            <Banner />
          </Row>
          <main>
            <AllRoutes />
          </main>
          <Row>
            <FooterNav />
          </Row>
          <Row>
            <Footer className="footer" />
          </Row>
        </Container>
      </HelmetProvider>
    </Router>
  );
}
