import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
//import * as serviceWorker from "./serviceWorker";
import reportWebVitals from "./reportWebVitals";

// ReactDOM.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>,
//   document.getElementById("root")
// );

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

//serviceWorker.unregister();
reportWebVitals();
