import React from "react";
import WebsiteTitle from "../WebsiteTitle";
import showYear from "../../utils/showYear";
import Image from "react-bootstrap/Image";
import "./footer.css";

export default function Footer() {
  const currentYear = new Date(Date.now()).getFullYear();
  const startYear = 2020;
  return (
    <footer className="container">
      <small>
        <div id="copyright">
          <strong>
            {" "}
            &copy; {showYear(currentYear, startYear)} {WebsiteTitle} | All
            Rights Reserved.{" "}
          </strong>{" "}
        </div>
        <Image
          className="algoliaImage"
          src="logo-algolia.svg"
          alt=""
          loading="lazy"
          width="80px"
          height="50px"
        />
      </small>
    </footer>
  );
}
