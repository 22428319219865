import React from "react";
import { Link } from "react-router-dom";
import Image from "react-bootstrap/Image";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import "./navigationbar.css";

export default function NavigationBar() {
  return (
    <Navbar expand="lg">
      <Navbar.Brand id="navbar-brand">
        <Nav.Link as={Link} to="/" id="main-nav-home">
          <Image src="/wsf_logo.png" height="75px" width="82px" alt="" />
        </Nav.Link>
      </Navbar.Brand>
    </Navbar>
  );
}
